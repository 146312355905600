import React from 'react'
import Contactus from "../../ScreenComponents/ContactComponents/Contactus"

const Contact = () => {
  return (
    <div>
      <Contactus/>
    </div>
  )
}

export default Contact
