import React from 'react';
import '../Components/Header.css';
import { Container, Row, Col } from 'react-bootstrap';
import phone from '../Assets/Assets/Bottom/phone.png';
import email from '../Assets/Assets/Bottom/email.png';
import location from '../Assets/Assets/Bottom/location.png';
import Ellipse from '../Assets/Assets/Bottom/Ellipse 24.png';
import whatsapp from '../Assets/Assets/Bottom/whatsapp.png';
import facebook from '../Assets/Assets/Bottom/facebook.png';
import insta from '../Assets/Assets/Bottom/insta.png';
import email2 from '../Assets/Assets/Bottom/email2.png';
import whatsapp2 from '../Assets/Assets/Bottom/whatsapp2.png';

const Bottom = () => {
  const quickLinks = ['Home', 'About us', 'Events', 'Training', 'Traffic Awareness Video'];

  const contactDetails = [
    { icon: whatsapp, text: '+91 0000000000' },
    { icon: phone, text: '+91 253 2315966' },
    { icon: email, text: 'secretary@nashikfirst.com' },
    { icon: location, text: 'Survey No. 590/591, Motkari Nagar, Near Nelson Hospital, Behind Tupsakhare Lawns, Near Mumbai Naka, Nashik.', isAddress: true },
  ];

  // Now each social link has the icon and URL together
  const socialLinks = [
    { icon: facebook, url: 'https://www.facebook.com/Nashikfirsttrafficpark/ ' },
    { icon: insta, url: 'https://www.instagram.com/nashikfirst/ ' },
    { icon: email2, url: 'mailto:your-email@example.com' },
    { icon: whatsapp2, url: 'https://wa.me/910000000000' }, // Replace with your actual WhatsApp number
  ];

  return (
    <>
      <section className='bottombg'>
        <Container fluid className='pb-5'>
          <Row className='loc'>
            <Col lg={5}>
              <p className='text-start bottomheadline ms-4 loc'>LOCATION</p>
              {/* Map iframe for desktop */}
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14998.042739114164!2d73.77339834282328!3d19.987070168042514!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bddeb1abfce8b67%3A0x31f677d2506ffcb7!2sMumbai%20Naka%2C%20Renuka%20Nagar%2C%20Nashik%2C%20Maharashtra!5e0!3m2!1sen!2sin!4v1724840869222!5m2!1sen!2sin"
                className='d-none d-md-block mt-3'
                style={{ width: '100%', height: '240px', borderRadius: '9px' }}
                loading="lazy"
              ></iframe>
              {/* Map iframe for mobile */}
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14998.042739114164!2d73.77339834282328!3d19.987070168042514!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bddeb1abfce8b67%3A0x31f677d2506ffcb7!2sMumbai%20Naka%2C%20Renuka%20Nagar%2C%20Nashik%2C%20Maharashtra!5e0!3m2!1sen!2sin!4v1724840869222!5m2!1sen!2sin"
                className='d-block d-md-none mt-3'
                style={{ width: '100%', height: '250px', borderRadius: '9px' }}
                loading="lazy"
              ></iframe>
            </Col>

            <Col sm={6} lg={3} className='mt-5 '>
              <p className='text-start bottomheadline pb-3'>Quick Links</p>
              {quickLinks.map((link, index) => (
                <Row key={index}>
                  <Col sm={1} xs={1} className='mt-3 ms-4'>
                    <img src={Ellipse} alt="Bullet" style={{ width: '15px' }} />
                  </Col>
                  <Col sm={10} xs={10} className='mt-3'>
                    <h5 className='text-start'>{link}</h5>
                  </Col>
                </Row>
              ))}
            </Col>

            <Col sm={12} lg={4} className="mt-5">
              <p className='text-start bottomheadline pb-3'>Contact Details</p>
              {contactDetails.map((detail, index) => (
                <Row className={`align-items-center mt-2 ${detail.isAddress ? 'pt-0' : ''}`} key={index}>
                  <Col lg={2} xs={2} className='p-0'>
                    <img src={detail.icon} alt="Icon" className={`footericon ${detail.isAddress ? 'mb-5' : ''}`} />
                  </Col>
                  <Col lg={10} xs={10} className='p-0'>
                    {detail.isAddress ? <p className="text-start" style={{fontSize:"19px", fontWeight:"600"}}>{detail.text}</p> : <h5 className="text-start ms-0 p-0">{detail.text}</h5>}
                  </Col>
                </Row>
              ))}

              <Row className="mt-0 pb-0">
                <Col lg={4} className='text-start p-0'>
                  <h4 className=' mt-2'>Follow Us On</h4>
                </Col>
                {socialLinks.map((link, index) => (
                  <Col lg={2} xs={2} key={index} style={{width:"70px"}} className='p-0'>
                    <a href={link.url} target="_blank" rel="noopener noreferrer">
                      <img src={link.icon} className='followicon' alt={`Social Icon ${index}`} />
                    </a>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Bottom;
