import React from 'react'
import Pastevents from '../../ScreenComponents/EventsComponents/Pastevents'
// import Upcomingevents from '../../ScreenComponents/EventsComponents/Upcomingevents'

const Events = () => {
  return (
  <div>
    <Pastevents/>
    {/* <Upcomingevents/> */}
  </div>
  )
}

export default Events
