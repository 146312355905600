import React from 'react'
import Calenderpage from '../../ScreenComponents/TrainingComponents/Calenderpage'
import Slotpage from '../../ScreenComponents/TrainingComponents/Slotpage'
// import Bookingpage from '../../ScreenComponents/TrainingComponents/Bookingpage'

const Training = () => {
  return (
    <>
    <Calenderpage/>
    {/* <Slotpage/> */}
    {/* <Bookingpage/> */}
    </>
  )
}

export default Training
