import React from 'react'
import AwarnessVideo from '../../ScreenComponents/VideoComponents/AwarnessVideo'
import Videolinks from '../../ScreenComponents/VideoComponents/Videolinks'

const Video = () => {
  return (
    <div>
      <AwarnessVideo/>
      <Videolinks/>
    </div>
  )
}

export default Video
